import { isLimited } from "../model";

import s from "./LimitedFeatures.module.scss";

interface Props {
  limitedCaptionText: string;
  features: Record<string, string>;
}

export const LimitedFeatures = ({ limitedCaptionText, features }: Props) => {
  return isLimited.value ? (
    <div className={s.limited}>
      <ul className={s.limited__features}>
        <li className={s.limited__feature}>{features["without-logo"]}</li>
        <li className={s.limited__feature}>{features["anonymity"]}</li>
      </ul>
      <span className={s.limited__caption}>{limitedCaptionText}</span>
    </div>
  ) : null;
};
